$xs: 360px;
$sm: 768px;
$md: 1024px;
$lg: 1248px;
$xl: 1584px;

@mixin xs-relative {
  @media screen and (min-width: $xs) {
    position: relative;
  }
}

@mixin xs-row {
  @media screen and (min-width: $xs) {
    flex-direction: row;
  }
}

@mixin xs-padding {
  @media screen and (min-width: $xs) {
    padding: 1rem;
  }
}

@mixin margin-md-sm {
  @media (max-width: $md) and (min-width: $sm) {
    margin-left: 100px;
  }
}

.card {
  &__content {
    @media screen and (min-width: $xs) {
      flex-direction: row;
    }
  }
}

.container,
.component-container,
.dashboard-container,
.contents {
  @media (min-width: $sm) {
    flex-direction: row;
    justify-content: space-around;
  }

  @media (max-width: $sm) {
    flex-direction: column;
  }
}
