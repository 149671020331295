/* You can add global styles to this file, and also import other style files */
@use "@voxel/foundation/scss/foundation-angular" as *;
@import '@voxel/tokens/varejo/tokens.css';

body,
html {
  background-color: var(--cultured);
}

img {
  display: block;
  max-width: 100%;
}

voxel-tabs {
  .voxel-tabs {
    display: flex;
    justify-content: center;
  }
}

voxel-form-selection {
  .voxel-form-selection {
    &__label--radio {
      margin-left: 0;
    }
  }
}

voxel-list {
  li {
    div {
      justify-content: center;
    }
  }
}

.no-margin {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.voxel-pl-s {
  padding-left: 0.5rem;
}

.voxel-likert {
  &__content {
    flex-wrap: wrap;
  }
}
