:root {
  --green: #00ff08;
  --yellow-solid: #ff0;
  --vermilion: #ff3e00;
  --desert-storm: #f0efee;
  --congress-blue: #004a8f;
  --emperor: #555;
  --principal: #ec7000;
  --isabelline: #efe9e5;
  --platinum: #e5e5e5;
  --cultured: #faf7f5;
  --black-chocolate: #231d19;
  --honolulu-blue: #106eb0;
}
